import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import HomePage from './components/HomePage';
import Pagination from './components/Pagination';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

console.log('REACT_APP_SUPABASE_URL:', process.env.REACT_APP_SUPABASE_URL);
console.log('REACT_APP_SUPABASE_ANON_KEY:', process.env.REACT_APP_SUPABASE_ANON_KEY);

function App() {
  const [allOpportunities, setAllOpportunities] = useState([]);
  const [filteredOpportunities, setFilteredOpportunities] = useState([]);
  const [carouselOpportunities, setCarouselOpportunities] = useState([]);
  const [user, setUser] = useState(null);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchAllOpportunities();
    fetchOpportunities();
  }, []);

  useEffect(() => {
    console.log('User state changed:', user);
  }, [user]);

  async function fetchAllOpportunities() {
    try {
      const response = await fetch('https://locumdemo.xyz/api/all-opportunities');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setAllOpportunities(data);
      setCarouselOpportunities(getRandomOpportunities(data, 3));
    } catch (error) {
      console.error('Error fetching all opportunities:', error);
      setError('Failed to fetch opportunities. Please try again later.');
    }
  }

  function getRandomOpportunities(opportunities, count) {
    const shuffled = [...opportunities].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  }

  async function fetchOpportunities(searchParams = {}, page = 1) {
    try {
      console.log('Fetching opportunities...', searchParams, 'Page:', page);
      const queryParams = new URLSearchParams({ 
        ...searchParams, 
        page, 
        pageSize: 12 
      }).toString();
      const response = await fetch(`https://locumdemo.xyz/api/opportunities?${queryParams}`);
      console.log('Response status:', response.status);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log('Fetched opportunities:', data);
      setFilteredOpportunities(data.opportunities);
      setCurrentPage(data.currentPage);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error('Error fetching opportunities:', error);
      setError('Failed to fetch opportunities. Please try again later.');
    }
  }

  function handleSearch(searchParams) {
    setCurrentPage(1);
    fetchOpportunities(searchParams, 1);
  }

  function handlePageChange(page) {
    setCurrentPage(page);
    fetchOpportunities({}, page);
  }

  async function handleSignUp(signUpData) {
    setError('');
    setMessage('');
    try {
      const response = await fetch('https://locumdemo.xyz/api/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(signUpData),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Signup failed');
      }
      setMessage(data.message);
      // Optionally, you can automatically sign in the user here
      // or update the user state with the returned user and profile data
      setUser(data.user);
      // You might want to store the profile data in a separate state if needed
    } catch (error) {
      console.error('Signup error:', error);
      setError(error.message);
    }
  }

  async function handleSignIn(email, password) {
    console.log('Attempting sign in with:', email);
    setError('');
    setMessage('');
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) throw error;
      console.log('Sign in successful, user:', data.user);
      setUser(data.user);
      setMessage('Sign in successful');
      return true;
    } catch (error) {
      console.error('Sign in error:', error);
      setError(error.message);
      return false;
    }
  }

  async function handleSignOut() {
    setError('');
    setMessage('');
    const { error } = await supabase.auth.signOut();
    if (error) {
      setError('Error signing out: ' + error.message);
    } else {
      setUser(null); // Clear the user state
      setMessage('Signed out successfully');
    }
  }

  async function handleUpdateProfile(profileData) {
    try {
      if (!user) {
        throw new Error('User not authenticated');
      }

      const response = await fetch(`https://locumdemo.xyz/api/user-profile/${user.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(profileData),
      });

      if (!response.ok) {
        throw new Error('Failed to update profile');
      }

      const data = await response.json();
      setMessage('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      setError(error.message);
    }
  }

  return (
    <Router>
      <div className="App">
        <HomePage 
          user={user} // Pass the user state directly
          onSignIn={handleSignIn}
          onSignUp={handleSignUp}
          onSignOut={handleSignOut}
          onUpdateProfile={handleUpdateProfile}
          carouselOpportunities={carouselOpportunities}
          filteredOpportunities={filteredOpportunities}
          onSearch={handleSearch}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          error={error}
          message={message}
        />
      </div>
    </Router>
  );
}

export default App;
